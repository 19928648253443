import * as contentful from 'contentful/dist/contentful.browser.min';




const SPACE_ID = 'd4lq8ktk1ujj';
const ACCESS_TOKEN = 'Py7J1BXIa5HhE-uv6MAThr_mXLcdWoWzZPp-VZ-gGKA';
const HOST = 'cdn.contentful.com';
export const ContentfulClient = contentful.createClient({
    space: SPACE_ID,
    accessToken: ACCESS_TOKEN,
    host: HOST,
});

const queryMethod = ContentfulClient.getEntries;
const createMethod = ContentfulClient.getSpace;


ContentfulClient.getEntries = function (query) {
    return queryMethod.apply(this, [query]).catch(e => {
        console.log("Unable to fetch data from contentfulManagement");
        console.log(e);
        return {
            total: 0,
            items: [],
        };
    });

};


const contentfulManagement = require('contentful-management')

const client = contentfulManagement.createClient({
    accessToken: 'CFPAT-fAJf511ThxQtDYEx45NWPia4qISvevNv1fillXsigXQ'
})






