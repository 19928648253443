import React, { useEffect, useState } from "react";
import { Layout } from "../components/layout";
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css';
import { ContentfulClient, createEntry } from "../contentful-config/ContentfulConfig";
import { Button } from "react-bootstrap";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import '../gallery.css';

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";


export default function Gallery() {
    const [gallery, setGallery] = useState([]);
    const [galleryFilter, setGalleryFilter] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('Door');
    const [index, setIndex] = useState(-1);

    const getGalleryData = async () => {
        try {
            const response = await ContentfulClient.getEntries({ 'content_type': 'gallery' });
            console.log("Gallery Response", response);
            if (response.items && response.items.length > 0) {
                const uniqueArray = response.items.map((item) => item.fields.galleryImageType);
                const galleryFilter = [...new Set(uniqueArray)];
                console.log("galleryFilter", galleryFilter);
                setGalleryFilter(galleryFilter);
                setGallery(response.items);
            }
        } catch (e) {
            console.log("Error Fetching data from contentful", e);
        }
    };

    const handleFilterSelect = (filter) => {
        setSelectedFilter(filter);
    };

    useEffect(() => {
        getGalleryData();
    }, []);

    const openLightbox = () => {
        console.log("shan")
    }

    const filteredGallery = gallery.filter((item) => item.fields.galleryImageType === selectedFilter);

    console.log("filteredGallery", filteredGallery);
    const imageUrls = filteredGallery.map((file) => file.fields.galleryImage.fields.file);
    // const images = imageUrls.map((url) => ({ src: url }));

    console.log("imageUrls", imageUrls);
    const galleryData = imageUrls.map((item) => {
        return {
            src: item.url,
            height: item.details.image?.height,
            width: item.details.image?.width
        };
    });

    return (
        <div>
            <Layout>
                <section className="container">
                    <div className="galleryFilter">
                        {galleryFilter.map((item, index) => (
                            <button className="outlined filterBtn" key={index} onClick={() => handleFilterSelect(item)}>{item}</button>
                        ))}
                    </div>
                    <section id="galleryMain" className="container">
                        <div className="row">
                            <div className="col-lg-6 order-lg-2">
                                <PhotoAlbum layout="rows" photos={galleryData} onClick={({ index }) => setIndex(index)} />
                            </div>
                            <div className="col-lg-6 order-lg-1">
                                <Lightbox
                                    slides={galleryData}
                                    open={index >= 0}
                                    index={index}
                                    close={() => setIndex(-1)}
                                    // enable optional lightbox plugins
                                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                />
                            </div>
                        </div>
                    </section>
                </section>
            </Layout>
        </div>
    );
}
