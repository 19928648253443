
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const Footer = (props) => {
    const navigate = useNavigate();
    return (
        <div className="footerMain">

            <div className='container'>

                <div className='row'>


                    <div className="col-6">
                        <p ><strong>Address:</strong> Woodioincs (Pvt) Ltd, Main High Court Road,Rawalpindi 41200, Pakistan.</p>
                        <p ><strong>Phone:</strong> +92 03405035250, &nbsp;<strong>Email:</strong>  info@woodionics.com</p>
                    </div>
                    <div className="col-6">
                        <ul >
                            <li><a
                                target="_blank" class="facebook"

                                href='https://www.facebook.com/profile.php?id=100069962992258&mibextid=ZbWKwL'
                                aria-label='Facebook'><i class="fa fab fa-fw fa-2x fa-inverse fa-facebook" aria-hidden="true"></i></a></li>
                            <li><a target="_blank" class="google-plus"
                                href='https://www.pinterest.com/woodionics/'
                            ><i class="fa fab fa-fw fa-2x fa-inverse fa-pinterest" aria-hidden="true"></i></a></li>
                            <li><a target="_blank" class="linkedin"
                                href="https://www.linkedin.com/in/woodionics-wood-482940279/"
                            ><i class="fa fab fa-fw fa-2x fa-inverse fa-linkedin" aria-hidden="true"></i></a></li>
                            <li><a target="_blank" class="instagram" ><i class="fa fab fa-fw fa-2x fa-inverse fa-instagram" aria-hidden="true"></i></a></li>
                        </ul>

                    </div>
                </div>

            </div>
            <div class="whatsapp_float_main">
                <a
                    href="https://wa.me/0923405035250"
                    class="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i class="fa fa-whatsapp whatsapp-icon"></i>
                </a>
            </div>
        </div>
    )
};
