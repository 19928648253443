import React from 'react';
import { Layout } from '../components/layout';
import about1 from '../images/about1.jpg';
import about2 from '../images/about2.jpg';
import about3 from '../images/about3.jpg';

function AboutUs() {
    return (
        <div>
            <Layout>
                <div className="container about-main">
                    <div className="row about-content-pannel">
                        <div className="col-12 col-md-6 order-md-2">
                            <img
                                className="about-img img-fluid img-thumbnail"
                                src={about1}
                                alt="about1"
                            />
                        </div>
                        <div className="col-12 col-md-6 about-content">
                            <h3>Welcome to Woodioincs!</h3>
                            <p>
                                At Woodioincs, we have a deep passion for wood and a commitment to providing high-quality wood products to our customers. We specialize in products such as custom furniture, handcrafted wooden decor, or sustainable wood materials. With years of experience and expertise in the industry, we take pride in our craftsmanship and attention to detail.
                            </p>
                        </div>
                    </div>
                    <div className="row about-content-pannel">
                        <div className="col-12 col-md-6">
                            <img
                                className="about-img img-fluid img-thumbnail"
                                src={about2}
                                alt="about2"
                            />
                        </div>
                        <div className="col-12 col-md-6 about-content">
                            <p>Our mission is to bring the beauty and warmth of wood into your everyday life. We believe that wood has a unique character and charm that adds a touch of elegance to any space. Whether you're looking to furnish your home, office, or any other environment, we offer a wide range of carefully curated wood products that combine functionality, aesthetics, and durability.</p>
                        </div>
                    </div>
                    <div className="row about-content-pannel">
                        <div className="col-12 col-md-6 order-md-2">
                            <img
                                className="about-img img-fluid img-thumbnail"
                                src={about3}
                                alt="about3"
                            />
                        </div>
                        <div className="col-12 col-md-6 about-content">
                            <p>
                                What sets us apart is our dedication to sustainability. We understand the importance of preserving our natural resources, which is why we source our wood from responsibly managed forests and employ eco-friendly practices throughout our production process. We strive to minimize waste and promote the use of renewable materials, ensuring that our products have a minimal impact on the environment.
                            </p>
                            <p>
                                At Woodioincs, we value our customers and aim to provide an exceptional shopping experience. Our team of skilled artisans and woodworkers works tirelessly to create stunning pieces that meet your unique needs and preferences. We also offer customization options, allowing you to bring your vision to life and own a truly one-of-a-kind item.
                            </p>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default AboutUs;
