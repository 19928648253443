import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import './responsive.css';
import Home from './Pages/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import AboutUs from './Pages/aboutus';
import Gallery from './Pages/gallery';
import ContactUs from './Pages/contactus';

function App() {
  return (
    <div className="app">
      <ParallaxProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contactus" element={<ContactUs />} />
          </Routes>
        </BrowserRouter>
      </ParallaxProvider>
    </div>
  );
}

export default App;
