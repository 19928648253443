import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/layout";
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PhotoAlbum from "react-photo-album";
import { Parallax } from 'react-scroll-parallax';

import { ContentfulClient, createEntry } from "../contentful-config/ContentfulConfig";
import { Button } from "react-bootstrap";

function Home() {

    const navigate = useNavigate();

    const [images, setImages] = useState([]);
    const [aboutinfo, setAboutinfo] = useState([]);
    const [galleryData, setGalleryData] = useState([]);
    const [testimonials, setTestimonials] = useState([]);

    const getBannerImages = async () => {
        try {
            const response = await ContentfulClient.getEntries({ 'content_type': 'banner' });
            if (response.items[0] && response.items.length > 0) {
                const images = response.items.map((item) => {
                    return item.fields.bannerImages[0].fields.file;
                });
                setImages(images);
            }
        } catch (e) {
            console.log("Error Fetching data from contentful", e);
        }
    };

    const getAboutData = async () => {
        try {
            const response = await ContentfulClient.getEntries({ 'content_type': 'about' });
            if (response.items[0] && response.items.length > 0) {
                const aboutinfo = response.items.map((item) => {
                    return item.fields;
                });
                setAboutinfo(aboutinfo);
            }
        } catch (e) {
            console.log("Error Fetching data from contentful", e);
        }
    };

    const getGalleryData = async () => {
        try {
            const response = await ContentfulClient.getEntries({ 'content_type': 'ourLatest' });
            if (response.items[0] && response.items.length > 0) {
                const galleryData = response.items.map((item) => {
                    const originalWidth = item.fields.latestImages?.fields.file.details.image.width;
                    const originalHeight = item.fields.latestImages?.fields.file.details.image.height;
                    const aspectRatio = originalHeight / originalWidth;
                    const width = Math.floor(Math.random() * 201) + 20;
                    const height = Math.round(width * aspectRatio);

                    return {
                        src: item.fields.latestImages?.fields.file.url,
                        height: height,
                        width: width
                    };
                });
                setGalleryData(galleryData);
            }
        } catch (e) {
            console.log("Error Fetching data from contentful", e);
        }
    };

    const getCustomerTestimonials = async () => {
        try {
            const response = await ContentfulClient.getEntries({ 'content_type': 'testimonials' });
            if (response.items[0] && response.items.length > 0) {
                const testimonials = response.items.map((item) => {
                    return item;
                });
                setTestimonials(testimonials);
            }
        } catch (e) {
            console.log("Error Fetching data from contentful", e);
        }
    };

    useEffect(() => {
        getBannerImages();
        getAboutData();
        getGalleryData();
        getCustomerTestimonials();
    }, []);

    return (
        <div>
            <Layout>
                <section className="">
                    <Carousel>
                        {images?.map((file, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100"
                                        src={file.url}
                                        alt="First slide"
                                    />
                                    <div className='shan'></div>
                                    <Carousel.Caption>
                                        <h3>{file.title}</h3>
                                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </section>

                <section className="aboutSection">
                    {aboutinfo?.map((info) => {
                        return (
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-6 order-md-1">
                                        <h2>{info.aboutHeading}</h2>
                                        <p>{info.aboutDetails.content[0].content[0].value}</p>
                                    </div>
                                    <div className="col-12 col-md-6 order-md-2">
                                        <img
                                            className="aboutImg"
                                            src={info.aboutImage.fields.file.url}
                                            alt="about img"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </section>


                <section className="galleryWrapper">
                    <Parallax translateY={[-30, 30]}>
                        <section className="galleryMain container-fluid">
                            {galleryData && <PhotoAlbum layout="masonry" photos={galleryData} />}
                        </section>
                    </Parallax>
                </section>

                <section className="testominalMain">
                    <section className="container testominalMain">
                        <Carousel>
                            {testimonials?.map((item, index) => {
                                return (
                                    <Carousel.Item key={index}>
                                        <Carousel.Caption className="testiminialContent">
                                            <h3>{item.fields.customerName}</h3>
                                            <p>{item.fields.description?.content[0]?.content[0].value}</p>
                                        </Carousel.Caption>
                                        <img
                                            className="testimonialImg"
                                            src={item.fields.customerImage.fields.file.url}
                                            alt="First slide"
                                        />
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    </section>
                </section>
            </Layout>
        </div>
    );
}

export default Home;
