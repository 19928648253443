import React from "react";
import {Header} from "./header";
import {Footer} from "./Footer";

export const Layout = (props)=> {
    return (
        <div>
            <Header/>
            <div className='main-content'>
            {props.children}
            </div>
<Footer/>
        </div>
    );
}
