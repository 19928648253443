import React from 'react';
import emailjs from 'emailjs-com';
import { Layout } from '../components/layout';

const ContactForm = () => {
    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_r78pxgm', 'template_hwj62od', e.target, 'ZmrHsDY2_mQBfpB5U')
            .then(
                (result) => {
                    console.log('Email sent successfully!', result.text);
                },
                (error) => {
                    console.error('Error sending email:', error.text);
                }
            );

        e.target.reset();
    };

    return (
        <Layout>
            <section className='container '>
                <section className='row aboutus'>
                    <section className='col-lg-6 order-lg-2'>
                        <form className="SendEmailForm" onSubmit={handleSubmit}>
                            <input className="form-control" type="text" name="from_name" placeholder="Your name" required />
                            <input className="form-control" type="email" name="email" placeholder="Your email" required />
                            <textarea className="form-control" name="message" placeholder="Your message" required></textarea>
                            <button className="outlined filterBtn" type="submit">Send</button>
                        </form>
                    </section>
                    <section className='contactUsForm col-lg-6 order-lg-1'>
                        <h3>We Will Get Back to You Soon</h3>
                        <p>Thank you for your interest in Woodionics! We are dedicated to providing exceptional service and support to our valued customers. If you have any questions, concerns, or feedback, please don't hesitate to reach out to us. We are here to help!</p>
                        <h2>How to Reach Us</h2>
                        <p>You can contact us through any of the following channels:</p>
                        <ul>
                            <li>
                                <b>Phone:</b> Give us a call at <b>+923405035250</b> during our business hours, <b>9am-6pm</b>, and our friendly customer service representatives will be glad to assist you.
                            </li>
                            <li>
                                <b>Email:</b> Send us an email at <b>info@woodionics.com</b>, and we will make it a priority to respond to your inquiry promptly. Please provide as much detail as possible to help us better understand and address your needs.
                            </li>
                            <li>
                                <b>Online Form:</b> Fill out our convenient online contact form below. We ask for some basic information, such as your name, email address, and a brief description of your query. Rest assured that the information you provide is kept confidential and used solely for the purpose of assisting you.
                            </li>
                            <li>
                                <b>Social Media:</b> Connect with us on our social media platforms, such as Facebook, Twitter, and Instagram. We actively monitor our social media accounts and will respond to your messages and comments as quickly as possible.
                            </li>
                        </ul>
                        <section>
                            <p>When you contact us, we understand that your time is valuable. We assure you that we will do our utmost to respond to your queries promptly. Our dedicated team is committed to providing comprehensive assistance and finding the best solutions to your needs.</p>
                            <p>Thank you for choosing Woodionics. We look forward to hearing from you and serving you with excellence!</p>
                        </section>
                    </section>
                </section>
            </section>
        </Layout>
    );
};

export default ContactForm;
